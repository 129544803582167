import Navbar from "./Navbar";
import MainContent from "./Main";
import Footer from "./Footer";

export default function PageWrapper(props: any) {
  return (
    <div className="mx-auto  " style={{ position: "relative", height: "100" }}>
      <div className="bunny-top-right">
        <img
          alt="arizza-girl"
          src="./girl_bunny.png"
          // src={placeholder}
          className="bunny-top-right-img"
        />
      </div>

      <div className="main-content">
        <Navbar />
        <div className="container mx-auto flex flex-col">
          <MainContent>{props.children}</MainContent>
        </div>
        <Footer>
          <div className="text-center w-full text-gray-800">
            <span
              onClick={() =>
                window.open("https://twitter.com/ArizzaTribe", "_blank")
              }
              className="cursor-pointer  font-bold hover:text-colorOrange-300"
            >
              Twitter
            </span>{" "}
            -{" "}
            <span
              onClick={() =>
                window.open("https://discord.gg/spfepfsNqZ", "_blank")
              }
              className="cursor-pointer  font-bold hover:text-colorOrange-300"
            >
              Discord
            </span>
          </div>
        </Footer>
      </div>
      <div className="bunny-bottom-left">
        <img
          alt="arizza-boy"
          src="./boy_bunny.png"
          // src={placeholder}
          className="bunny-bottom-left-img"
        />
      </div>
    </div>
  );
}
