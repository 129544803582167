import { withRouter } from "react-router";
import SectionBox from "../components/layout/SectionBox";
import Gallery2 from "../components/layout/Gallery2";
import FAQ from "../components/FAQ";
import About from "../components/About";
import Mint from "../components/Mint";
import Goals from "../components/Goals";

function Homepage(props: any) {
  let imgs = [
    { alt: "alttext", url: "./photo2.jpg" },
    { alt: "alttext", url: "./photo3.jpg" },
    { alt: "alttext", url: "./photo4.jpg" },
    { alt: "alttext", url: "./photo1.jpg" },
    { alt: "alttext", url: "./boy_bunny.png" },
    { alt: "alttext", url: "./photo5.jpg" },
  ];

  return (
    <div className="max-w-screen-xl mx-auto">
      <SectionBox>
        <div className="mt-10">
          {/* <MintPreCandy /> */}
          <Mint
            candyMachineId={props.candyMachineId}
            config={props.config}
            connection={props.connection}
            startDate={props.startDate}
            treasury={props.treasury}
            txTimeout={props.txTimeout}
          />
        </div>
      </SectionBox>
      <SectionBox>
        <div className="mt-10 flex flex-col p-8 text-2xl text-gray-900  shadow-lg bg-gray-100 max-w-5xl mx-auto rounded-xl items-center relative-position">
          <Gallery2 imgs={imgs} />
        </div>
      </SectionBox>
      <SectionBox>
        <Goals />
      </SectionBox>
      <SectionBox>
        <About />
      </SectionBox>
      <SectionBox>
        <FAQ />
      </SectionBox>
    </div>
  );
}

export default withRouter(Homepage);
