import FAQuestion from "./layout/FAQuestion";
import FAQuestionContent from "./layout/FAQuestionContent";
import FAQuestionContentPar from "./layout/FAQuestionContentPar";
import FAQuestionTitle from "./layout/FAQuestionTitle";

export default function Goals(props: any) {
  return (
    <div className="mt-20 flex flex-col p-8 bg-gray-100 max-w-5xl shadow-lg mx-auto rounded-xl items-center relative-position">
      <div className="floating-top-right">
        <img
          alt="weed"
          src="./floating/Weed_2.png"
          // src={placeholder}
          className="floating-top-right-img"
        />
      </div>
      <div className="text-3xl text-gray-900 font-bold mt-3 mb-9">
        Our Goals & Visions
      </div>
      <FAQuestion>
        <FAQuestionTitle>
          🌎 Fund distribution after mint closes
        </FAQuestionTitle>
        <FAQuestionContent>
          <FAQuestionContentPar>
            👉 10% of proceeds will go towards the Arizza community treasury.
            With this money, all holders will get an opportunity to vote on
            where funds should be donated or used for any other purpose proposed
            by members.
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            ⚡ Our idea: We would love to connect with Arizza holders in the
            real world. That’s why we propose that the treasury funds can be
            used towards flying out Arizza friends to Colombia next year, to
            visit the beautiful country with us and help doing charity work.
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            👉 10% of proceeds will go towards the first LatamDAO on Solana. The
            LatamDAO is made up of multiple Latin American projects that will
            create a strong environment for Latin American artists, developers
            and people that are generally interested in crypto and NFTs, to find
            a community and work together. Community members of the DAO will
            decide on funds distribution between vetted charities, helping out
            upcoming Latam projects and hosting events.
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            👉 Our goal is to help physically in communities in Latin America
            where funds and resources are needed to better quality of life.
          </FAQuestionContentPar>
        </FAQuestionContent>
      </FAQuestion>

      
    </div>
  );
}
