import { Route, Switch } from "react-router";
import Homepage from "./pages/Homepage";
// import Collections from "./pages/Collections";
// import CollectionDashboard from "./pages/CollectionDashboard";
import PageWrapper from "./components/layout/PageWrapper";

export default function MainRouter(props: any) {
  return (
    <div style={{ height: "100%" }}>
      <Switch>
        <Route exact path="/">
          <div className="h-full">
            <PageWrapper>
              <Homepage
                candyMachineId={props.candyMachineId}
                config={props.config}
                connection={props.connection}
                startDate={props.startDate}
                treasury={props.treasury}
                txTimeout={props.txTimeout}
              />
            </PageWrapper>
          </div>
        </Route>
      </Switch>
    </div>
  );
}
