export default function Footer(props: any) {
  return (
    <div>
      <div className=" p-8 mt-5   py-4  ">
        {props.children}
      </div>
      
    </div>
  );
}
