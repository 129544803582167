export default function Gallery2(props: any) {
  return (
    <>
      <div className="hidden md:grid md:grid-cols-3 gap-8">
        <div className="floating-top-left">
          <img
            alt="crown"
            src="./floating/Cactus.png"
            // src={placeholder}
            className="floating-top-left-img"
          />
        </div>
        {props.imgs &&
          props.imgs.map((img: any, i: number) => {
            if (i !== 4) {
              return (
                <div key={i} className="w-full">
                  <img
                    alt={img.alt}
                    src={img.url}
                    // src={placeholder}
                    className="rounded-xl  shadow-lg w-full mx-auto"
                  />
                </div>
              );
            } else {
              return (
                <div key={i} className="w-full font-bold flex items-center p-4">
                  2 sisters with a vivid imagination and lovers of wild
                  bunny/human hybrids. Come join us!
                </div>
              );
            }
          })}
      </div>
      <div className="md:hidden grid grid-cols-2 md:grid-cols-3 gap-8">
        <div className="floating-top-left">
          <img
            alt="crown"
            src="./floating/Cactus.png"
            // src={placeholder}
            className="floating-top-left-img"
          />
        </div>
        {props.imgs &&
          props.imgs.slice(0, 4).map((img: any, i: number) => {
            return (
              <div key={i} className="w-full">
                <img
                  alt={img.alt}
                  src={img.url}
                  // src={placeholder}
                  className="rounded-xl  shadow-lg w-full mx-auto"
                />
              </div>
            );
          })}
      </div>
      <div className="w-full md:hidden font-bold flex mt-4 items-center p-4">
        2 sisters with a vivid imagination and lovers of wild bunny/human
        hybrids. Come join us!
      </div>
    </>
  );
}
