// import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Link } from "react-router-dom";
// import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
// import { shortenAddress } from "../../candy-machine";

export default function Navbar(props: any) {
  // const wallet = useAnchorWallet();
  return (
    <nav className=" py-4 my-8 ">
      <div className="max-w-5xl mx-auto ">
        <div className="flex items-center justify-between h-16">
          <div className=" flex-1 items-center">
            {/* <Link className="flex-shrink-0 text-2xl font-bold" to="/">
              ARIZZA
            </Link> */}
            {/* <div className="bg-colorGreen-300 rounded-xl">
              <Link className=" text-2xl font-bold " to="/">
                <img
                  alt="Arizza Logo"
                  src="./logo1.png"
                  className="w-full h-auto md:w-auto md:max-h-96 pt-12"
                />
              </Link>
            </div> */}
          </div>
          <div className=" flex-1 text-center items-center ">
            <div className=" rounded-xl">
              <Link className=" text-2xl font-bold " to="/">
                <img
                  alt="Arizza Logo"
                  src="./logo_cropped.png"
                  className="w-full h-auto md:w-auto md:max-h-96 mx-auto"
                />
              </Link>
            </div>
          </div>
          <div className="flex-1">
            {/* <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="x relative ml-auto">
                  <div className="relative inline-block text-left">
                    <div>
                      hi
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
