import { useEffect, useState } from "react";
// import Countdown from "react-countdown";
import { CircularProgress } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";
// import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../candy-machine";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

export default function Mint(props: any) {
  //   const [balance, setBalance] = useState<number>();

  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  //   const [itemsAvailable, setItemsAvailable] = useState(0);
  // const [itemsRedeemed, setItemsRedeemed] = useState(0);
  //   const [itemsRemaining, setItemsRemaining] = useState(0);

  //   const [successMint, setSuccessMint] = useState(false);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));
  console.log(startDate);

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      setStartDate(new Date(props.startDate));
      const {
        candyMachine,
        // goLiveDate,
        // itemsAvailable,
        itemsRemaining,
        // itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      //   setItemsAvailable(itemsAvailable);
      //   setItemsRemaining(itemsRemaining);
      // setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);

      // setStartDate(goLiveDate);
      setCandyMachine(candyMachine);

      // REMOVE::::
      // setIsMinting(false);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          //   setSuccessMint(true);
        } else {
          setAlertState({
            open: true,
            message:
              "Mint request timed out and could have failed! Please double check if transaction finalized or try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      //   if (wallet) {
      //     const balance = await props.connection.getBalance(wallet.publicKey);
      //     setBalance(balance / LAMPORTS_PER_SOL);
      //   }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  //   useEffect(() => {
  //     (async () => {
  //       if (wallet) {
  //         const balance = await props.connection.getBalance(wallet.publicKey);
  //         setBalance(balance / LAMPORTS_PER_SOL);
  //       }
  //     })();
  //   }, [wallet, props.connection]);
  useEffect(() => {}, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <div>
      <div>
        <div>
          <>
            <div className="mb-3 text-gray-800 font-bold text-2xl">
              Mint is live!
            </div>
            <div className="mb-5 text-gray-800 font-bold text-lg">
              0.5 SOL per bunny
            </div>
          </>

          {!wallet ? (
            <>
              <div className="walletButtonColor">
                <WalletDialogButton
                  style={{
                    borderRadius: "0.5rem",
                    backgroundColor: "#F97C0C",
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                  }}
                >
                  Connect Wallet
                </WalletDialogButton>
              </div>
            </>
          ) : (
            <>
              <button
                type="button"
                disabled={isSoldOut || isMinting}
                onClick={onMint}
                className={`py-4 px-6 rounded-xl ${
                  isSoldOut || isMinting
                    ? "bg-colorGreen-500 text-color2-500 text-xl cursor-default"
                    : "cursor-pointer text-white bg-colorOrange-300 text-xl min-w-8 hover:bg-colorOrange-100 font-bold bg-color1-100 hover:bg-color4-500"
                }`}
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : (
                  <>
                    {isMinting ? (
                      <div className="pt-2">
                        <CircularProgress
                          style={{
                            color: "white",
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    ) : (
                      "MINT"
                    )}
                  </>
                )}
              </button>
            </>
          )}
          {wallet && (
            <div className="mt-5 text-gray-800 font-bold text-lg">
              {shortenAddress(wallet.publicKey.toBase58() || "")}
            </div>
          )}
          <div className="mt-3 text-gray-800 font-bold text-2xl">
            Make sure you're on https://arizza.vercel.app to mint!
          </div>
        </div>
      </div>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}
