export default function About(props: any) {
  return (
    <div className="mt-20  w-full text-gray-800 text-2xl font-bold flex flex-col p-8 bg-gray-100 max-w-5xl shadow-lg mx-auto rounded-xl items-center relative-position">
      <div className="floating-top-center">
        <img
          alt="horns"
          src="./floating/Horns.png"
          // src={placeholder}
          className="floating-top-center-img"
        />
      </div>
      <div className="text-3xl text-gray-900 font-bold mt-3 mb-6">
        Who we are
      </div>
      <div className="text-center mb-6 text-gray-800 text-xl">
        <div>🎨 Maria, the 16 year old artist </div>
        <div>👩‍💼 Morena, the founder of the project </div>
        <div>💻 Manu, the older brother helping out with the tech</div>
      </div>
      <div className="flex flex-wrap   w-full">
        <div className="w-full md:w-1/2  py-8 md:p-8">
          <div className="bg-colorGreen-100 p-4  md:p-8 rounded-xl shadow-lg">
            <div>
              <img
                alt="maria"
                src="./morena_sol.png"
                // src={placeholder}
                className=" max-h-44 rounded-xl mx-auto shadow-lg "
              />
            </div>
            <div className="text-center w-full my-6  text-gray-900 font-bold text-2xl">
              Note from Morena (founder)
            </div>
            <div >
              <div className="text-left w-full mb-3 text-gray-800 font-bold text-lg">
                Nothing has been more exciting for me than watching the digital
                art space expand! The NFT world is constantly changing and
                getting more interesting. Now we’re finally part of it!
              </div>
              <div className="text-left w-full mb-3 text-gray-800 font-bold text-lg">
                Besides crypto and NFTs I’m also passionate about helping those
                in need. Due to living in developing countries most of my life
                I’ve always been exposed to the inequalities. Through our Arizza
                Tribe, I feel for the first time that we can have a big impact
                in children’s lives in these suffering communities.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2  py-8 md:p-8">
          <div className="bg-colorGreen-100 p-4  md:p-8 rounded-xl shadow-lg">
            <div>
              <img
                alt="maria"
                src="./maria_sol.png"
                // src={placeholder}
                className=" max-h-44 rounded-xl mx-auto shadow-lg "
              />
            </div>
            <div className="text-center w-full my-6  text-gray-900 font-bold text-2xl">
              Note from Maria (artist)
            </div>
            <div>
              <div className="text-left w-full mb-3 text-gray-800 font-bold text-lg">
                Drawing and painting has been something I do for fun for years
                and it was always a dream of mine to put my skills to good use.
                So happy my older siblings introduced me to the NFT world, where
                I can showcase my art and help children in Latin America! Can’t
                wait to make a difference with you all.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
