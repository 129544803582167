import FAQuestion from "./layout/FAQuestion";
import FAQuestionContent from "./layout/FAQuestionContent";
import FAQuestionContentPar from "./layout/FAQuestionContentPar";
import FAQuestionTitle from "./layout/FAQuestionTitle";

export default function FAQ(props: any) {
  return (
    <div className="mt-20 flex flex-col p-8 bg-gray-100 max-w-5xl shadow-lg mx-auto rounded-xl items-center relative-position">
      <div className="floating-top-right">
        <img
          alt="pizza1"
          src="./floating/Pizza_1.png"
          // src={placeholder}
          className="floating-top-right-img"
        />
      </div>
      <div className="text-3xl text-gray-900 font-bold mt-3 mb-9">
        Common Questions
      </div>
      <FAQuestion>
        <FAQuestionTitle>
          What are these weird looking Arizza bunnies?
        </FAQuestionTitle>
        <FAQuestionContent>
          <FAQuestionContentPar>
            🐰 999 algorithmically generated OG Arizza NFT bunnies on Solana.
            Completely unique with over 100+ traits.
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            🐰 A bunch of super rare bunnies that will have completely
            individual traits that no other bunny will have 💫
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            🐰 Easter eggs are included in the metadata of 20 random bunnies.
            Anyone who mints one of them will receive a prize in SOL as
            mentioned in its description.
          </FAQuestionContentPar>
        </FAQuestionContent>
      </FAQuestion>

      <FAQuestion>
        <FAQuestionTitle>
          What is the reason driving this project?
        </FAQuestionTitle>
        <FAQuestionContent>
          <FAQuestionContentPar>
            We moved around developing countries from a young age where the
            immense inequalities in children’s education were very noticeable 🌍
            We could take part in programs to help out schools and families but
            we never felt like we could make a huge difference in these kid’s
            lives.
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            It was when Maria showed us some of her art she had been working on
            that it immediately clicked for all of us. Creating an NFT project
            with the goal of improving children’s lives! Long-term visions of
            bringing more charities in Latin America to the crypto world and
            starting our own foundation to increase the quality and
            accessibility of children’s education! 🎓
          </FAQuestionContentPar>
        </FAQuestionContent>
      </FAQuestion>

      {/* <FAQuestion>
        <FAQuestionTitle>
          What happens after purchasing an Arizza bunny?
        </FAQuestionTitle>
        <FAQuestionContent>
          <FAQuestionContentPar>
            👉 You’ll be supporting an epic all-female led project & young
            artists 👩‍🎨
          </FAQuestionContentPar>
          <FAQuestionContentPar>
            👉 If the launch goes well, get ready for the next Arizza generation
            to be dropped. This will be extra exciting, since the different ages
            will play a role in terms of rarity and rewards. Will you be lucky
            enough to snag all three same-looking Arizza bunny family members?
            👩‍👧‍👦
          </FAQuestionContentPar>
        </FAQuestionContent>
      </FAQuestion> */}

      <FAQuestion>
        <FAQuestionTitle>
          Sounds pretty promising... What will the mint price be?
        </FAQuestionTitle>
        <FAQuestionContent>
          <FAQuestionContentPar>
            Our OG Arizza Tribe will be offered for 0.5 SOL each at a supply of
            999.
          </FAQuestionContentPar>
        </FAQuestionContent>
      </FAQuestion>
    </div>
  );
}
